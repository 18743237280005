import { Panel } from '../../components/layout/panel'
import { Page } from '../../components/page/page'
import styles from './footer.module.css'

export function Privacy() {
    return <Page><Panel><PrivacyContent /></Panel></Page>
}

function PrivacyContent() {
    return <div className={styles.infoPage}>
        <h1>Privacy Policy</h1>
        <h2>What information do we collect?</h2>
        <p>
            We collect information from you when you register on our site, enter a game, respond to a survey or fill out a form.
            <br /> When ordering or registering on our site, as appropriate, you may be asked to enter your: name; email, postal address, phone number, credit card information or bank details.
        </p>
        <h2>What do we use your information for?</h2>
        <p>
            Any of the information we collect from you may be used in one of the following ways:
            <br /> &nbsp;&nbsp;&nbsp;to personalise your experience;
            <br /> &nbsp;&nbsp;&nbsp;to improve our website;
            <br /> &nbsp;&nbsp;&nbsp;to improve customer service;
            <br /> &nbsp;&nbsp;&nbsp;to process transactions
            <br /> &nbsp;&nbsp;&nbsp;to administer games, promotions, surveys or other site features;
            <br /> &nbsp;&nbsp;&nbsp;to send periodic emails;
            <br />
            <br /> The email address you provide for order processing, may be used to send you information and updates pertaining to games, in addition to receiving occasional company news, updates, related product or service information,
            etc.
            <br />
            <br /> Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased
            product or service requested.
        </p>
        <h2>How do we protect your information?</h2>
        <p>
            We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive/credit information
            is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our payment gateway providers database only to be accessible by those authorized with special access rights to such systems.
        </p>
        <h2>Do we use cookies?</h2>
        <p>
            Yes (Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain
            information. We use cookies to help us remember understand and save your preferences for future visits and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in
            the future. We may contract with third-party service providers to assist us in better understanding our site visitors. These service providers are not permitted to use the information collected on our behalf except to help us conduct
            and improve our business.
        </p>
        <h2>Do we disclose any information to outside parties?</h2>
        <p>
            We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties
            agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety.
            However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
        </p>
        <h2>Third party links</h2>
        <p>
            Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities
            of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
        </p>
        <h2>Online Privacy Policy Only</h2>
        <p>
            This online privacy policy applies only to information collected through our website and not to information collected offline.
        </p>
        <h2>Terms and Conditions</h2>
        <p>
            Please also visit our Terms and Conditions section establishing the use, of liability governing the use of our website at http://www.upredict.co.uk/#/terms
        </p>
        <h2>Your Consent</h2>
        <p>
            Please also visit our Terms and Conditions section establishing the use, of liability governing the use of our website at http://www.upredict.co.uk/#/terms
        </p>
        <h2>Changes to our Privacy Policy</h2>
        <p>
            If we decide to change our privacy policy, we will send an email notifying you of any changes, and/or update the Privacy Policy modification date below.
        </p>
        <h2>Contacting Us</h2>
        <p>
            If there are any questions regarding this privacy policy you may contact us using following email: admin@upredict.co.uk
        </p>
    </div>
}

