import useFsmReducer, { UseFsmReducerParams } from 'use-fsm-reducer';

export function useStateMachine<TState extends {
  type: string;
} = any, TAction extends {
  type: string;
} = any, TEffect extends {
  type: string;
} = any>(params : UseFsmReducerParams<TState, TAction, TEffect>) {
  const [state, dispatch] = useFsmReducer<TState, TAction, TEffect>(params)

  const canRespondTo = (action : TAction['type'] ) : boolean => {
    if(Object.hasOwn(params.on ?? {},action)) return true
    if(Object.hasOwn(params.states, state.type) && Object.hasOwn(params.states[state.type as TState['type']]?.on ?? {}, action)) return true

    return false
  }

  const respondsTo = [...Object.keys(params.on ?? {}), ...Object.keys((params.states[state.type as TState['type']] ?? {})?.on ?? {})]

  return [state, dispatch, canRespondTo, respondsTo] as const
}