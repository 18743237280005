import { Dialog } from '@reach/dialog'
import { ReactNode, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import styles from './dialogue.module.css'
import { Button } from '../buttons/buttons'

export type DialogueContents = { title: string, children: ReactNode }
export type Action = (() => void) | (() => Promise<void>)
export type LabelledAction = { action: Action, label: string }
type DialogueProps = DialogueContents & { show: boolean, onClose?: Action, actions: LabelledAction[], showCancel?: boolean }

export function ShowDialogue({ title, children, onClose }: DialogueContents & { onClose?: Action }) {
  const [show, setShow] = useState<boolean>(true)

  return <Dialogue title={title} show={show} actions={[{ action: () => { setShow(false); onClose?.() }, label: 'OK' }]}>{children}</Dialogue>
}

export function Dialogue({ show, title, children, onClose, actions, showCancel = true }: DialogueProps) {
  return <Dialog isOpen={show} onDismiss={onClose} className={styles.dialogue} aria-label={title} >
    <h1>{title}<button aria-label="Cancel" onClick={onClose}><FontAwesomeIcon icon={faTimes} /></button></h1>
    <p role='status' aria-label="Confirm Contents">{children}</p>
    <div className={styles.confirmActions}>
      {actions.map((a) => { return <Button onClick={a.action} key={a.label}>{a.label}</Button> })}
      {!!onClose && showCancel ? <Button onClick={onClose}>Cancel</Button> : null}
    </div>
  </Dialog>
}