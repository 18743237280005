import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons'
import gamblingCommission from '../../images/gambling-commission.png'
import eighteenPlus from '../../images/18+.png'
import gamStop from '../../images/svg/gam-stop.svg'
import beGambleAware from '../../images/svg/be-gamble-aware.svg'
import styles from './footer.module.css'
import { Path } from '../../paths'

export function Footer() {
    return <footer className={styles.footer}>
        <p>uPredict Ltd. is licensed and regulated in Great Britain by the Gambling Commission under account number <a target='_blank' rel='noreferrer' href='https://registers.gamblingcommission.gov.uk/57903'>57903</a>.</p>

        <p className={styles.imagery}>
            <a className={styles.socialBubble} href='https://www.facebook.com/upredict' target='_blank' rel='noreferrer'>
                <FontAwesomeIcon icon={faFacebook} />
            </a>

            <a className={styles.socialBubble} href='https://twitter.com/upredict_it' target='_blank' rel='noreferrer'>
                <FontAwesomeIcon icon={faTwitter} />
            </a>
        </p>

        <p>
            <Link to={Path.terms}>Terms of Use</Link>
            <span>&nbsp;/&nbsp;</span>
            <Link to={Path.privacy}>Privacy Policy</Link>
            <span>&nbsp;/&nbsp;</span>
            <Link to='#' onClick={(e) => { window.location.href = 'mailto:admin@upredict.co.uk'; e.preventDefault() }} >Contact Us</Link>
        </p>

        <p className={styles.thirdparty}>
            <a href='https://www.gamblingcommission.gov.uk' className={styles.gc} target='_blank' rel='noreferrer'>
                <img src={gamblingCommission} alt="Gambling Commission" />
            </a>
            <a href='https://www.gamstop.co.uk' target='_blank' rel='noreferrer'>
                <img src={gamStop} alt="Gam Stop" />
            </a>
            <a href='https://www.begambleaware.org' target='_blank' rel='noreferrer'>
                <img src={beGambleAware} alt="Be Gamble Aware" />
            </a>
            <img src={eighteenPlus} className={styles.eighteen} alt="18+" />
        </p>

        <p className={styles.copyright}>Copyright © {DateTime.now().toFormat('yyyy')} uPredict Ltd. <br />c/o Lisa Lines Accountancy Services Ltd., 23 Lydford Close,
            Ivybridge, PL210YW, United Kingdom</p>
    </footer>
}