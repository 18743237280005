import { Component, ErrorInfo, ReactNode } from 'react'
import { alert } from '../../utilities/logging/logging'
import { UnresolvableApiError } from '../../utilities/http/jsonApi'
import { Path } from '../../paths'
import { ShowDialogue } from '../../components/dialogue/dialogue'
import { HttpStatusCode } from '../../utilities/http/http'

type ErrorProps = { children: ReactNode }
type ErrorState = { hasError: boolean }

const clientAlertName = 'Client Alert'
export class ErrorHandler extends Component<ErrorProps, ErrorState> {
    constructor(props: ErrorProps) {
        super(props)
        this.state = { hasError: false }
        this.promiseRejectionUnhandled = this.promiseRejectionUnhandled.bind(this)
        this.onError = this.onError.bind(this)
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        alert(clientAlertName, 'React component did catch error', error, { url: window.location.href, ...info })
    }

    static getDerivedStateFromError(): Partial<ErrorState> {
        return { hasError: true }
    }

    componentDidMount() {
        window.addEventListener('unhandledrejection', this.promiseRejectionUnhandled)
        window.addEventListener('error', this.onError)
    }

    promiseRejectionUnhandled(event: PromiseRejectionEvent) {
        const isApiError = UnresolvableApiError.IsOfType(event?.reason)

        if (!isApiError)
            alert(clientAlertName, `Unhandled Promise Rejection`, null, { reason: event?.reason, url: window.location.href })
        else if(!redirectOnError(event) && apiHasNotAlreadyLogged(event))
            alert(clientAlertName, `API Errored`, null, { reason: event?.reason, url: window.location.href })

        if (isApiError && redirectOnError(event))
            window.location.replace(`/#${Path.home}`)
        else
            this.setState({ hasError: true })
    }

    onError(event: ErrorEvent) {
        alert(clientAlertName, 'Javascript on error', event.error, { error: event, url: window.location.href })
        this.setState({ hasError: true })
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.promiseRejectionUnhandled)
        window.removeEventListener('error', this.onError)
    }

    render() {
        if (this.state.hasError)
            return <ShowDialogue title='Oops!' onClose={() => {
                window.location.replace(Path.home)
                this.setState({ hasError: false })
            }}>
                Apologies but something has gone wrong. Our engineers have been notified and will be looking into it.
            </ShowDialogue>

        return this.props.children
    }
}

function redirectOnError(event: PromiseRejectionEvent) {
    const statusCode = (event?.reason as UnresolvableApiError).status.code
    return statusCode === HttpStatusCode.Unauthorized || statusCode === HttpStatusCode.NotFound
}

function apiHasNotAlreadyLogged(event: PromiseRejectionEvent) {
    return (event?.reason as UnresolvableApiError).status.code !== HttpStatusCode.InternalServerError
}