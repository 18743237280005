import { GameType } from './features/game/type'

export const Path = {
  root: '',
  home: '/',
  dashboard: '/dashboard',
  account: '/account',
  deposited: '/account/deposited',
  withdrawn: '/account/withdrawn',
  signup: '/signup',
  terms: '/terms',
  privacy: '/privacy',
  recentlyPlayed: '/recently-played',
  unknown: '*',
  game(id: string) { return `/game/${id}` },
  rules(game?: GameType) { return `/game/${game ?? ':type'}/rules` },
  predictions: {
    relative: 'predictions',
    for(game: string) {
      return Path.game(game) + '/' + Path.predictions.relative
    }
  },
  standings: {
    relative: 'standings',
    for(game: string) {
      return Path.game(game) + '/' + Path.standings.relative
    }
  },
  minileague: {
    relative: 'mini-league',
    for(game: string) {
      return Path.game(game) + '/' + Path.minileague.relative
    }
  }
}

export const Urls = {
  home(referee?: string) {
    return !!referee ? `https://upredict.co.uk/#/home?referrer=${referee}` : 'https://upredict.co.uk/#/home'
  }
}