import { GameType, GameContent } from './type';

export const Games : Record<GameType, GameContent> = {
  'epl': { 
    name: 'Prem Predictor',
    description: "uPredict's Prem Predictor is simple. Predict results for a Premier League matchday.  Earn points for accuracy and bonus points for shocks. Points mean prizes!.",
    bonusPointText: "Bonus points are awarded when correctly predicting a win for teams lower down the league table. They are updated on completion of the final match before a matchday begins and calculated based on the difference between the two teams in the league table.",
    prizeGrowthText: [
      "Prize pot will grow for every 5 players who join",
      "From 1 to 100, by £7.50 per 5 players",
      "From 101 to 200, by £12.50 per 5 players",
      "201 and above, by £17.50 per 5 players",
      "One free entry per 20 players"
    ]
  },
  'euro': {
    name: 'Euro Predictor',
    description: "uPredict's Euro Predictor is simple. Predict results in a Euro round. Earn points for accuracy and bonus points for shocks. Points mean prizes!",
    bonusPointText: "Bonus points are awarded when correctly predicting a win for a team lower down the Fifa rankings. Each team has been allocated from 1 to 24 based on their Fifa ranking going into the tournament. The bonus points for each match are calculated based on the difference between the two teams in their 1 to 24 allocation.",
    prizeGrowthText: [
      "Prize pot will grow for every 5 players who join",
      "From 1 to 100, by £7.50 per 5 players",
      "From 101 to 200, by £12.50 per 5 players",
      "201 and above, by £17.50 per 5 players",
      "One free entry per 20 players"
    ]
  }
}
