import { Tour } from '.'
import { GameType } from '../../type'

import { predictionReel as epl1 } from './reels/epl/predictions'
import { inGameReel as epl2 } from './reels/epl/in-game'
import { socialReel as epl3 } from './reels/epl/social'

import { predictionReel as euro1 } from './reels/euro/predictions'
import { inGameReel as euro2 } from './reels/euro/in-game'
import { socialReel as euro3 } from './reels/euro/social'

export const Tours : Record<GameType, Tour>  = {
  'epl': {
    reels: [epl1, epl2, epl3]
  },
  'euro': {
    reels: [euro1, euro2, euro3]
  }
}
