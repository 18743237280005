import { Footer } from './features/footer/footer'
import logo from './images/svg/upredictlogo.svg'
import { Container } from './components/layout/container'
import { Panel } from './components/layout/panel'
import styles from './static-message.module.css'

export function StaticMessage() {
  return <>
    <Container>
      <img src={logo} alt='uPredict' className={styles.logo} />
      <div>
        <Panel className={styles.panel}>
        Dear Customer,
<p>uPredict has been going for a considerable amount of time now and while we have tried everything in our power to grow our fan base, people come and go, unfortunately people are no longer playing at a faster rate than people are joining.</p>
<p>We are having a re-think about how to grow the user base at a quicker rate so we can keep this great game going and growing. With that we will take a pause on the game while we re-consider the options open to us. This is a sad day for us, and we know that we have a hardcore following that will be sad to not be playing every week.</p>
<p>We understand that some of you have money in your accounts, please contact admin@upredict.co.uk if you cannot wait for us to come back online. Either way we have a record of everyone's accounts and rest assured you will be contacted and refunded if we don’t return.</p>
<p>Watch this space and we will be back in touch with everyone when we get a way forward for this game.</p>
<p>Apologies for any inconvenience, we don’t want it to end as much as you don't.</p>
Team uPredict
        </Panel>
      </div>

    </Container>
    <Footer />
  </>
}