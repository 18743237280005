import Logger from './logging'

function replaceErrors(key: string, value: any) {
  if (value instanceof Error) {
    var error: Record<string, any> = {}
    Object.getOwnPropertyNames(value).forEach(name => { error[name] = (value as any)[name] })

    return error
  }

  return value
}

export function createLogger(): Logger {
  return {
    log(entry: object) {
      fetch(window.configuration.logging.url, {
        method: 'POST',
        body: JSON.stringify({ ...entry, source: 'react' }, replaceErrors)
      })
    }
  }
} 
