import { Reel } from '../../../tour'
import inGame2 from './images/in-game/2.png'
import inGame3 from './images/in-game/3.png'
import inGame4 from './images/in-game/4.png'
import inGame5 from './images/in-game/5.png'
import inGame6 from './images/in-game/6.png'
import inGame7 from './images/in-game/7.png'
import inGame8 from './images/in-game/8.png'
import inGame9 from './images/in-game/9.png'
import inGame10 from './images/in-game/10.png'
import inGame11 from './images/in-game/11.png'

const defaultTop = 26

let inGameReel: Reel = [
  {
    type: 'jsx',
    top: 0,
    caption: '',
    render() {
      return <>
        <p>Now that you've made your predictions.</p>

        <p>Let's see how the game runs.</p>

        <p>And react to changes as the matchday progresses</p>
      </>
    }
  },
  {
    type: 'image',
    image: inGame2,
    top: defaultTop,
    caption: `Once the first fixture kicks off you can check your standings`,
    duration: 3000
  },
  {
    type: 'image',
    image: inGame3,
    top: 32,
    caption: `Your position is highlighted in greeen`,
    duration: 2500
  },
  {
    type: 'image',
    image: inGame4,
    top: 32,
    caption: `You can expand any player's entry to see their score breakdown`,
    duration: 1500
  },
  {
    type: 'image',
    image: inGame4,
    top: 39,
    caption: `You can expand any player's entry to see their score breakdown`,
    duration: 500
  },
  {
    type: 'image',
    image: inGame5,
    top: 39,
    caption: `You can expand any player's entry to see their score breakdown`,
    duration: 2500
  },
  {
    type: 'image',
    image: inGame6,
    top: 85,
    caption: `Predictions can be changed until 5 minutes before kick off`,
    duration: 3500
  },
  {
    type: 'image',
    image: inGame7,
    top: 85,
    caption: `Timers show how much longer each fixture has left`,
    duration: 2000
  },
  {
    type: 'image',
    image: inGame8,
    top: 85,
    caption: `Timers show how much longer each fixture has left`,
    duration: 1000
  },
  {
    type: 'image',
    image: inGame9,
    top: 85,
    caption: `Timers show how much longer each fixture has left`,
    duration: 1000
  },
  {
    type: 'image',
    image: inGame10,
    top: 85,
    caption: `Timers show how much longer each fixture has left`,
    duration: 2500
  },
  {
    type: 'image',
    image: inGame11,
    top: 53,
    caption: 'You\'re changes have worked! You\'ve shot up the table',
    duration: 3500
  },
  {
    type: 'image',
    image: inGame11,
    top: 28,
    caption: 'Now what about your friends...',
    duration: 2500
  }
]

export { inGameReel }