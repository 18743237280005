import { useUserStore } from '../../components/page/user.store'
import { useToggle } from '../../hooks/use-toggle'
import styles from './header.module.css'
import { Path } from '../../paths'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faRightFromBracket, faHouse, faUser, faTimeline } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export function Menu() {
  const [isOpen, toggle] = useToggle(false)
  const user = useUserStore()

  return <>
    <div className={styles.menuToggle} role='button' aria-label='uPredict Menu' onClick={toggle}>
      <FontAwesomeIcon icon={faBars} />
    </div>
    {isOpen ? <ul className={styles.menuItems}>
      <MenuItem text='Games' icon={faHouse} path={Path.dashboard} />
      {window.togglesOn && user.signedIn && user.playerProfile.recentlyPlayed.some(e => e) ? <MenuItem text="Recently Played" icon={faTimeline} path={Path.standings.for(user.playerProfile.recentlyPlayed[0].id)} /> : null}
      {!window.togglesOn && user.signedIn && user.playerProfile.hasPlayed ? <MenuItem text="Recently Played" icon={faTimeline} path={Path.recentlyPlayed} /> : null}
      {user.signedIn ? <MenuItem text='My Account' icon={faUser} path={Path.account} /> : null}
      {user.signedIn ? <li className={styles.contents} onClick={user.signOut} aria-label='Sign Out' role='button'><FontAwesomeIcon icon={faRightFromBracket} />Sign Out</li> : null}
    </ul> : null}
  </>
}

function MenuItem({ text, icon, path }: { text: string, icon: IconProp, path: string }) {
  return <li><Link to={path} aria-label={text} className={styles.contents} ><FontAwesomeIcon fixedWidth icon={icon} /><span>{text}</span></Link></li>
}

