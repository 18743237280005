import { Maybe } from '../../global'
import { HttpMethod, HttpStatus, createHttpStatus, HttpStatusCode } from './http'

export type JsonPayload = string | number | boolean | Date | JsonObject | JsonPayload[]
type JsonObject = { [key: string]: undefined | null | string | number | boolean | Date | JsonObject | JsonPayload[] }
export type JsonProblem = { title: string, errors: string[] }
export type ProblemHandler = (problem: JsonProblem) => void

export async function get<T>(url: string, token?: Maybe<string>, problemHandler?: ProblemHandler): Promise<T> { return call(HttpMethod.Get, url, token, problemHandler) as Promise<T> }
export async function post(url: string, payload: JsonPayload, token?: Maybe<string>, problemHandler?: ProblemHandler): Promise<null> { return call(HttpMethod.Post, url, token, problemHandler, payload) }
export async function put(url: string, payload: JsonPayload, token?: Maybe<string>, problemHandler?: ProblemHandler): Promise<void> { return call(HttpMethod.Put, url, token, problemHandler, payload) }
export async function del(url: string, token?: Maybe<string>, problemHandler?: ProblemHandler): Promise<void> { return call(HttpMethod.Delete, url, token, problemHandler) }

async function call(method: HttpMethod, url: string, token: Maybe<string>, problemHandler?: ProblemHandler, payload?: JsonPayload) {
    const mediaType = 'application/json'
    const headers: Record<string, string> = { 'Accept': mediaType }

    if (payload)
        headers['Content-Type'] = mediaType
    if (token)
        headers['Authorization'] = `Bearer ${token}`

    const response = await fetch(url, {
        method, headers,
        body: (payload ? JSON.stringify(payload) : null)
    })
    return jsonOrError(response, url, method, problemHandler, payload)
}

async function jsonOrError(response: Response, url: string, method: HttpMethod, problemHandler?: ProblemHandler, payload?: JsonPayload) {
    const body = await response.json().catch(_ => null)
    if (response.ok)
        return body
    else {
        const problem = coallesceToProblem(body)
        if (problemHandler && isDisplayableProblem(problem, response.status) && problem.errors.some(() => true))
            problemHandler(problem)
        else
            throw new UnresolvableApiError({ url, method, payload }, createHttpStatus(response.status), coallesceToProblem(body))
    }
}

function coallesceToProblem(problem: unknown): JsonProblem {
    return isProblem(problem) ? problem as JsonProblem : { title: 'Unknown Problem', errors: [] }
}

function isProblem(problem: unknown) {
    return typeof (problem as JsonProblem)?.title === 'string' && Array.isArray((problem as JsonProblem)?.errors)
}

function isDisplayableProblem(problem: JsonProblem, statusCode: HttpStatusCode) {
    return problem.errors.some(() => true) && (statusCode === HttpStatusCode.BadRequest || statusCode === HttpStatusCode.Conflict || statusCode === HttpStatusCode.UnavailableForLegalReasons)
}

type ErroredRequest = { url: string, method: string, payload?: JsonPayload }

export class UnresolvableApiError extends Error {
    constructor(request: ErroredRequest, status: HttpStatus, problem: JsonProblem, message?: string) {
        super(message)
        Object.setPrototypeOf(this, new.target.prototype)
        this.request = request
        this.status = status
        this.problem = problem
    }
    readonly request: ErroredRequest
    readonly status: HttpStatus
    readonly problem: JsonProblem

    static IsOfType(error: unknown): error is UnresolvableApiError {
        const the_error = error as UnresolvableApiError
        return !!the_error?.request?.url && !!the_error?.request?.method && !!the_error.status && !!the_error.problem
    }
}