import { Link as ReactRouterLink, LinkProps } from 'react-router-dom'
import styles from './buttons.module.css'

export function Button(props: Omit<JSX.IntrinsicElements['button'], 'className'>) {
  return <button className={styles.button} {...props}></button>
}

export function CallToAction(props: Omit<LinkProps, 'className'>) {
  return <ReactRouterLink className={styles.callToAction} {...props} />
}

export function LinkButton(props: Omit<JSX.IntrinsicElements['button'], 'className'>) {
  return <button className={styles.link} {...props} />
}