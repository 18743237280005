import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Path } from '../../paths'
import { DialogueContents, ShowDialogue } from '../dialogue/dialogue'

export function KnownErrorsHandler() {
  const auth0Error = useAuth0Errors()
  const goTo = useNavigate()
  const [showError, setShowError] = useState<DialogueContents | null>(null)

  useEffect(() => {    
    if (!showError) {
      if (auth0Error.type === 'Unknown')
        throw auth0Error.error

      if (auth0Error.type === 'AccountUnverified')
        setShowError({ title: 'Please verify your account!', children: <>You have been sent an email with a link to verify your uPredict account. Please follow the link and you will be able to sign in and play the game.</> })
    }
  }, [showError, auth0Error])
  
  return <>{showError ? <ShowDialogue title={showError.title} onClose={() => clearAuth0ErrorFromUrl(goTo)}>{showError.children}</ShowDialogue> : null}</>
}

function clearAuth0ErrorFromUrl(goTo: NavigateFunction) {  
  window.location.replace(`/#${Path.home}`)
}

type Auth0Error = { type: 'None' } | { type: 'AccountUnverified' } | { type: 'Unknown'; error: Error }

function useAuth0Errors() {
  const { error } = useAuth0()
  let auth0Error: Auth0Error = { type: 'None' }

  if (error)
    auth0Error = (error as unknown as { error_description: string }).error_description === 'AccountUnverified' ? { type: 'AccountUnverified' } : { type: 'Unknown', error: error }

  return auth0Error
}