import { Maybe } from '../../global'

let writer: Logger = { log: (entry: object) => { console.log(entry) } }

export function configure(logger: Logger) { writer = logger }

export function log(entry: object) { writer.log({ ...entry, 'log.level': 'Information' }) }

export function alert(name: string, message: string, error: Maybe<Error>, info?: object) {
    writer.log({ 'log.level': `Error`, name, message, exceptionDetail: error, additionalInfo: info || null })
}

export default interface Logger { log: (entry: object) => void }
