import create from 'zustand'
import { useCallback, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Maybe } from '../../global'
import { useGetAccountRequest } from '../../api/get-account'
import { AccountSummary } from '../../features/account/account.types'
import { useSearchParams } from 'react-router-dom'

export type Visitor = { referrer: Maybe<string>, signedIn: false, signIn: () => Promise<void> }
export type Registree = AccountSummary & {signedIn: true, signOut: () => void }
export type User = Registree | Visitor

export function useLoadUser() {
    const [searchParams] = useSearchParams()
    const { isAuthenticated, isLoading, user, loginWithRedirect, logout } = useAuth0()
    const getAccountRequest = useGetAccountRequest()
    const [isLoadingUser, setIsLoadingUser] = useState(true)
    const { user: uPredictUser, setUser } = userStore()
    const referrer = uPredictUser?.signedIn ? undefined : searchParams.get('referrer') ?? (uPredictUser as Visitor)?.referrer

    useEffect(() => {
        async function getAccount() {
            const account = await getAccountRequest()
            setUser({ username: account.username, playerProfile: account.playerProfile, balance: account.balance, promotions: account.promotions, signedIn: true, signOut: () => logout({ returnTo: window.location.origin }) })
            setIsLoadingUser(false)
        }

        if (!isLoading) {
            if (isAuthenticated)
                getAccount()
            else {
                setUser({ referrer: referrer, signedIn: false, signIn: loginWithRedirect })
                setIsLoadingUser(false)
            }
        }
    }, [getAccountRequest, isAuthenticated, isLoading, loginWithRedirect, logout, referrer, searchParams, setUser, user])

    return isLoadingUser
}

export function useRefreshAccount() {
    const getAccountRequest = useGetAccountRequest()
    const setUser = userStore(x => x.setUser)
    const { logout } = useAuth0()

    return useCallback(async (): Promise<void> => {
        const account = await getAccountRequest()
        setUser({ username: account.username, playerProfile: account.playerProfile, balance: account.balance, promotions: account.promotions, signedIn: true, signOut: () => logout({ returnTo: window.location.origin }) })
    }, [getAccountRequest, logout, setUser])
}

export function useUserStore(): User { return userStore(x => x.user!) }

type UserStore = { user: Maybe<User>, setUser(user: User): void }
const userStore = create<UserStore>(set => ({
    user: null,
    setUser(user: User) { set({ user: user }) }
}))