import React from 'react'
import ReactDOM from 'react-dom'
import "@reach/dialog/styles.css"
import { Routes, Route, HashRouter } from 'react-router-dom'
import { enableAllPlugins } from 'immer'
import { createLogger } from './utilities/logging/logzio'
import { configure } from './utilities/logging/logging'
import { ErrorHandler } from './features/error-handling/error-handler'
import { PageTracker } from './features/home/page-tracker'
import { Path } from './paths'
import './index.css'
import './utilities/prevent-auto-zoom'
import { Terms } from './features/footer/terms'
import { Privacy } from './features/footer/privacy'
import { Toaster } from 'react-hot-toast'
import { StaticMessage } from './static-message'

type Configuration = { logging: { url: string }, auth: { domain: string, clientId: string, audience: string }, getAddress: { apiKey: string } }
declare global {
    interface Window {
        configuration: Configuration
        togglesOn: boolean
    }
}

enableAllPlugins()
if (!/localhost/.test(window.location.href))
    configure(createLogger())

let redirectUri = new URL(window.location.origin)
redirectUri.hash = Path.dashboard

ReactDOM.render(
    <React.StrictMode>
        <HashRouter>
            <ErrorHandler>
                <PageTracker />
                <Routes>
                    <Route index path={Path.home} element={<StaticMessage />} />
                    <Route path={Path.terms} element={<Terms />} />
                    <Route path={Path.privacy} element={<Privacy />} />
                    <Route path={Path.unknown} element={<StaticMessage />} />
                </Routes>
            </ErrorHandler>
        </HashRouter>
        <Toaster />
    </React.StrictMode>,
    document.getElementById('react'))
