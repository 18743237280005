import cx from 'classnames'
import styles from './games.module.css'
import { CallToAction } from '../../components/buttons/buttons'
import { Path } from '../../paths'
import { useEffect, useState } from 'react'
import { useGet } from '../../utilities/http/use-authenticated-api'
import routes from '../../api/routes'
import { DateTime } from 'luxon'
import { formatMoney } from '../../global'

export function Games() {
  const nextGame = useNextGame()

  return <ul className={styles.games}>
    {!!nextGame ? <Game name='Prem Predictor' subtitle='' game={nextGame} >
      
    </Game> : null}
  </ul>
}

type GameProps = React.PropsWithChildren<{ name: string, subtitle: string, game: NextGame }>
function Game({ name, children, subtitle, game }: GameProps) {
  // change round name to be actual round name
  return <li className={styles.game}>
    <h1 className={styles.gameHeader}>{name}<span className={styles.subtitle}>{subtitle}</span></h1>
    <div className={styles.round}>Matchday {game.matchdays.requested.ordinal}: Entry Closes On {formatDate(game.matchdays.requested.start)}</div> 

    <div>
      <div className={styles.details}>
        <Trophy />
        <div aria-label='Pot' className={styles.pot}>{formatMoney((game.offering.first + game.offering.second + game.offering.third + game.offering.fourth + game.offering.fifth))}</div>
        <div className={styles.potLabel}>Pot</div>

        <div className={styles.entryLabel}>Entry</div>
        <div aria-label='Cash Stake' className={styles.cashStake}>£5</div>

        <div className={styles.playersLabel}>Players</div>
        <div className={styles.players}>{game.offering.players}</div>
      </div>

      <div className={styles.increase}>Prize pot increases again in {game.offering.nextIncrease} players</div>
    </div>

    {children}

    <div className={styles.actions}>
      <CallToAction to={Path.game(game.matchdays.requested.id)}>Learn More</CallToAction>
      <CallToAction to={Path.signup}>SIGN UP</CallToAction>
    </div>
  </li>
}

function Trophy() {
  return <svg className={cx(styles.largeIcon, styles.trophy)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0" />
  </svg>
}

type NextGame = {
  matchdays: { requested: { id: string, ordinal: number, start: string } },
  offering: { players: number, first: number, second: number, third: number, fourth: number, fifth: number, entries: number, nextIncrease: number }
}

function useNextGame() {
  const [nextGame, setNextGame] = useState<NextGame | null>()
  const get = useGet()

  useEffect(() => {
    async function getNextGame() {
      const game = await get<NextGame>(routes.nextGame)
      setNextGame(game)
    }

    getNextGame()
  }, [get])

  return nextGame
}

function formatDate(stringDate: string) {
  const date = DateTime.fromISO(stringDate)
  return date.toLocaleString({ ...DateTime.DATE_HUGE, weekday: 'short', month: 'short', year: '2-digit' })
}