import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function PageTracker() {
  const { pathname } = useLocation()

  useEffect(() => {            
      gtag('event', 'page_view', {
        page_title: pathname,
        page_location: pathname,
        page_path: pathname,
        send_to: 'G-KB5CNY91WQ'
      })    
  }, [pathname])

  return <></>
}
