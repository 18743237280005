import { get, put, post, del, JsonPayload, ProblemHandler } from './jsonApi'
import { useAuth0 } from '@auth0/auth0-react'
import { useCallback } from 'react'

export function useGet() {
    const getToken = useGetAuthenticationToken()
    return useCallback(async <T>(url: string, problemHandler?: ProblemHandler) => await get<T>(url, await getToken(), problemHandler), [getToken])
}

export function usePut() {
    const getToken = useGetAuthenticationToken()
    return useCallback(async (url: string, payload: JsonPayload, problemHandler?: ProblemHandler) => await put(url, payload, await getToken(), problemHandler), [getToken])
}

export function usePost() {
    const getToken = useGetAuthenticationToken()
    return useCallback(async (url: string, payload: JsonPayload, problemHandler?: ProblemHandler) => await post(url, payload, await getToken(), problemHandler), [getToken])
}

export function useDelete() {
    const getToken = useGetAuthenticationToken()
    return useCallback(async (url: string, problemHandler?: ProblemHandler) => await del(url, await getToken(), problemHandler), [getToken])
}

function useGetAuthenticationToken() {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0()
    return useCallback(async () => isAuthenticated ? await getAccessTokenSilently() : null, [isAuthenticated, getAccessTokenSilently])
}