
const routes = {
    games: '/games',
    game(id?: string) { return `/games/${id ?? 'current'}` },
    nextGame: '/games/next',
    enterGame(id: string) { return `/games/${id}/enter` },    
    standings(id: string) { return `/games/${id}/standings` },
    miniLeague(id: string) { return `/games/${id}/mini-league` },
    predict(id: string, reference: number) { return `/games/${id}/fixtures/${reference}/prediction` },
    account: '/account',
    purchaseTokens: '/account/tokens',
    signin: '/account/signin',
    transactions: '/account/transactions',
    deposit: '/account/transactions/deposits',
    recentlyPlayed: '/account/recently-played',
    follow: '/account/follow',
    depositsPage(amount: number) { return `account/transactions/deposits/page?amount=${amount}` },
    withdrawalPage(amount: number) { return `account/transactions/withdrawals/page?amount=${amount}` }
}

export default routes