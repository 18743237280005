import { isIOS } from 'react-device-detect'

if (isIOS) {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content')
    if (!content?.includes('maximum-scale')) {
      content = `${content}, maximum-scale=1.0`
    }
    el.setAttribute('content', content)
  }
}