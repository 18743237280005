import { useCallback } from 'react'
import { AccountSummary, Entry } from '../features/account/account.types'
import { Money } from '../global'
import { useGet } from '../utilities/http/use-authenticated-api'
import routes from './routes'


type AccountResponse = { username: string, playerProfile: { isEntrant: boolean, hasPlayed: boolean, recentlyPlayed: Entry[], playing: Entry[] }, balance: { cash: Money, tokens: number }, promotions: Promotion[] }
type Promotion = { description: string, awardMessage: string, howToClaim: ClaimType }
type ClaimType = "None" | "OnEntry" | "PurchaseTokens" 

export function useGetAccountRequest() {
  const get = useGet()
  return useCallback(async (): Promise<AccountSummary> => {
    return await get<AccountResponse>(routes.account)
  }, [get])
}