import { useStateMachine } from '../../../../hooks/use-state-machine'
import { Step, Tour } from '.'

type TourState =
  | { type: 'reel-start', step: Step, reelIndex: number, stepIndex: number }
  | { type: 'reel-end', step: Step, reelIndex: number, stepIndex: number }
  | { type: 'mid-reel', step: Step, reelIndex: number, stepIndex: number }
  | { type: 'finished', step: Step, reelIndex: number, stepIndex: number }

type TourActions =
  | { type: 'start' }
  | { type: 'last-reel' }
  | { type: 'next' }
  | { type: 'replay-reel' }
  | { type: 'restart-tour' }

type TourEffects = { type: 'auto-next', after: number }

export function useTour(tour: Tour) {
  const [state, dispatch, canRespondTo] = useStateMachine<TourState, TourActions, TourEffects>({
    initialState: { type: 'reel-start', step: tour.reels[0][0], reelIndex: 0, stepIndex: 0 },
    states: {
      'reel-start': {
        on: {
          start(state) {
            const step = tour.reels[state.reelIndex][1]
            return { type: 'mid-reel', step, reelIndex: state.reelIndex, stepIndex: 1, effects: [autoMove(step.duration!)] }
          },          
          "replay-reel"(state) {
            const step = tour.reels[state.reelIndex - 1][1]
            return { type: 'mid-reel', step, reelIndex: state.reelIndex - 1, stepIndex: 1, effects: [autoMove(step.duration!)] }
          }
        }
      },
      'mid-reel': {
        on: {          
          next(state) {
            const nextStep = state.stepIndex + 1
            const isEndOfReel = nextStep === tour.reels[state.reelIndex].length - 1
            const isLastReel = state.reelIndex === tour.reels.length - 1
            const step = tour.reels[state.reelIndex][nextStep]
            const effects = isLastReel && isEndOfReel ? [] : [autoMove(step.duration!)]
            return { type: isEndOfReel ? (isLastReel ? 'finished' : 'reel-end') : 'mid-reel', step, reelIndex: state.reelIndex, stepIndex: nextStep, effects }
          }
        }
      },
      'reel-end': {
        on: {
          next(state) {
            const nextReel = state.reelIndex + 1
            const step = tour.reels[nextReel][0]
            return { type: 'reel-start', step, reelIndex: nextReel, stepIndex: 0, effects: [autoMove(step.duration!)] }
          }
        }
      },
      'finished': {
        on: {
          'restart-tour'() {
            return { type: 'reel-start', step: tour.reels[0][0], reelIndex: 0, stepIndex: 0 }
          },
          "replay-reel"(state) {
            const step = tour.reels[state.reelIndex][1]
            return { type: 'mid-reel', step, reelIndex: state.reelIndex, stepIndex: 1, effects: [autoMove(step.duration!)] }
          }
        }
      }
    },
    on: {
      'last-reel'() {
        return { type: 'reel-start', step: tour.reels[1][0], reelIndex: 1, stepIndex: 0 }
      }
    },
    effects: {
      'auto-next'({effect: { after }, dispatch}) {
        setTimeout(() => { dispatch({type:'next'}) }, after)
      }
    }
  })
 
  function autoMove(after: number) : TourEffects {
    return { type: 'auto-next', after }
  }

  return {
    ...state,
    start: () => dispatch({ type: 'start' }),
    next() { dispatch({ type: 'next' }) },
    replayReel() { dispatch({ type: 'replay-reel' }) },
    restartTour() { dispatch({ type: 'restart-tour' }) },
    lastReel() { dispatch({ type: 'last-reel' }) },
    canRespondTo
  }
}