import predictions1 from './images/predictions/1.png'
import predictions2 from './images/predictions/2.png'
import predictions3 from './images/predictions/3.png'
import predictions4 from './images/predictions/4.png'
import predictions5 from './images/predictions/5.png'
import predictions6 from './images/predictions/6.png'
import predictions7 from './images/predictions/7.png'
import predictions8 from './images/predictions/8.png'
import bonus1 from './images/predictions/bonus1.png'
import bonus2 from './images/predictions/bonus2.png'
import bonus3 from './images/predictions/bonus3.png'
import bonus4 from './images/predictions/bonus4.png'
import predictionsAll from './images/predictions/all.png'
import enter from './images/predictions/enter.png'
import styles from '../reels.module.css'
import { Games } from '../../../../games'
import { Reel } from '../../../tour'

const defaultTop = 26
const predictionsTop = 88

export const predictionReel: Reel = [
  {
    type: 'jsx',
    top: 0,
    caption: '',
    render() {
      return <>
        <p className={styles.p1}>{Games['euro'].description}</p>

        <p>Minimum pot of £250. Prizes paid down to 5th with free entries also to be won. The more players the bigger the prize! Full game rules are below.</p>

        <p>Play to see how it works!</p>
      </>
    }
  },
  {
    type: 'image',
    image: predictions1,
    top: predictionsTop,
    caption: `Make your predictions`,
    duration: 1000
  },
  {
    type: 'image',
    image: predictions2,
    top: predictionsTop,
    caption: `Make your predictions`,
    duration: 1000
  },
  {
    type: 'image',
    image: predictions3,
    top: predictionsTop,
    caption: `Make your predictions`,
    duration: 1000
  },
  {
    type: 'image',
    image: predictions4,
    top: predictionsTop,
    caption: `Make your predictions`,
    duration: 700
  },
  {
    type: 'image',
    image: predictions5,
    top: predictionsTop,
    caption: `Make your predictions`,
    duration: 500
  },
  {
    type: 'image',
    image: predictions6,
    top: predictionsTop,
    caption: `Make your predictions`,
    duration: 500
  },
  {
    type: 'image',
    image: predictions7,
    top: predictionsTop,
    caption: `Make your predictions`,
    duration: 500
  },
  {
    type: 'image',
    image: predictions8,
    top: predictionsTop,
    caption: `Make your predictions`,
    duration: 500
  },
  {
    type: 'image',
    image: predictionsAll,
    top: predictionsTop,
    caption: `Make your predictions`,
    duration: 700
  },
  {
    type: 'image',
    image: bonus1,
    top: predictionsTop,
    caption: `Teams lower in the FIFA rankings have bonus points`,
    duration: 3250
  },
  {
    type: 'image',
    image: bonus1,
    top: predictionsTop,
    caption: `Based on their relative ranking at the start of the tournament`,
    duration: 3250
  },
  {
    type: 'image',
    image: bonus2,
    top: predictionsTop,
    caption: `But you only earn the bonus when predicting them to win!`,
    duration: 1000
  },
  {
    type: 'image',
    image: bonus3,
    top: predictionsTop,
    caption: `But you only earn the bonus when predicting them to win!`,
    duration: 1000
  },
  {
    type: 'image',
    image: bonus4,
    top: predictionsTop,
    caption: `But you only earn the bonus when predicting them to win!`,
    duration: 2000
  },
  {
    type: 'image',
    image: enter,
    top: defaultTop,
    caption: `With your predictions made. Don't forget to enter`,
    duration: 3250
  }
]
