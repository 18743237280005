import { Reel } from '../../../tour';

import standingsTop from './images/social/standings-top.png'
import followHighlighted from './images/social/follow-highlighted.png'
import followingAFriend from './images/social/following-a-friend.png'
import followingAStranger from './images/social/following-a-stranger.png'
import shareHighlighted from './images/social/share-highlighted.png'

export const socialReel: Reel = [
  {
    type: 'jsx',
    top: 0,
    caption: '',
    render() {
      return <>
        <p>uPredict is more fun when you play with your friends</p>

        <p>Fid out how to invitew friends</p>

        <p>Follow other players</p>

        <p>And earn free game entries!</p>
      </>
    }
  },
  {
    type: 'image',
    image: shareHighlighted,
    top: 28,
    caption: 'Earn free entries by referring your friends.',
    duration: 3500
  },
  {
    type: 'image',
    image: followingAFriend,
    top: 45,
    caption: 'When they join they show up in you mini-league',
    duration: 3500
  },
  {
    type: 'image',
    image: followingAFriend,
    top: 45,
    caption: 'You can easily see who has the bragging rights',
    duration: 3500
  },
  {
    type: 'image',
    image: followHighlighted,
    top: 50,
    caption: 'You can also follow any other player from the game',
    duration: 3500
  },
  {
    type: 'image',
    image: followingAStranger,
    top: 45,
    caption: 'and they will appear in your mini-league',
    duration: 3500
  },
  {
    type: 'image',
    image: standingsTop,
    top: 0,
    caption: 'See below for detailed game rules, and join today',
    duration: 4000
  },
  {
    type: 'image',
    image: standingsTop,
    top: 0,
    caption: 'See below for detailed game rules, and join today',
    duration: 2000
  }
]
