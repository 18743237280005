import { Link, useNavigate } from 'react-router-dom'
import { Registree, Visitor, useUserStore } from '../../components/page/user.store'
import styles from './header.module.css'
import { ReactComponent as Token } from '../../images/svg/token.svg'
import { Button, LinkButton } from '../../components/buttons/buttons'
import { Path } from '../../paths'
import { formatMoney } from '../../global'
import { Menu } from './menu'
import logo from '../../images/svg/upredictlogo.svg'
import cx from 'classnames'

export function Header({ contents }: { contents?: React.ReactNode }) {
  const user = useUserStore()

  return <div className={styles.background}>
    <div className={cx(styles.header, { [`${styles.signedIn}`]: user.signedIn })}>
      {user.signedIn ? <User registree={user} /> : null}

      <Link to={Path.dashboard} className={styles.logo}><img src={logo} alt="Go Home" /></Link>

      <Menu />

      {!user.signedIn ? <Authenticate visitor={user} /> : null}

      {contents}
    </div>
  </div>
}

function User({ registree }: { registree: Registree }) {
  const goTo = useNavigate()

  return <div className={styles.user}>
    <span><LinkButton onClick={() => goTo(Path.account)}>Account: {formatMoney(registree.balance.cash)}</LinkButton></span>
    <span className={styles.tokens} aria-label="Token Balance"><Token /><span className={styles.tokenAmount}>{registree.balance.tokens}</span></span>
  </div>
}

function Authenticate({ visitor }: { visitor: Visitor }) {
  const goTo = useNavigate()

  return <div className={styles.authentication}>
    <Button onClick={visitor.signIn}>Sign In</Button>
    <Button onClick={() => goTo(Path.signup)}>Sign Up</Button>
  </div>
}


