import { useCallback, useEffect, useState } from 'react'
import { Registree, useUserStore } from './user.store'
import { ShowDialogue } from '../dialogue/dialogue'
import { useSigninRequest } from '../../api/signin'

export function SigninToApi() {
  const { show, message, onClose } = useSigninToApi()
  return show ? <ShowDialogue title="Signin problem" onClose={onClose}>{message}</ShowDialogue> : null
}

function useSigninToApi() {
  const signinToApi = useSigninRequest()
  const { signedIn, signOut } = useUserStore() as Registree
  const [state, setState] = useState({ show: false, message: '' })

  useEffect(() => {
    async function checkSelfExclusion() {
      await signinToApi(problem => { setState({ show: true, message: problem.errors.join('. ') }) })
    }

    if (signedIn) checkSelfExclusion()
  }, [signedIn, signinToApi])

  const onClose = useCallback(() => { signOut() }, [signOut])

  return { ...state, onClose }
}