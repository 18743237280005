import styles from './page.module.css'
import headerStyles from '../../features/home/home.module.css'
import { ReactNode } from 'react'
import { useLoadUser } from './user.store'
import { Footer } from '../../features/footer/footer'
import { Header } from '../../features/header/header'
import { KnownErrorsHandler } from './known-errors-handler'
import { SigninToApi } from "./signin-to-api"

import predict from '../../images/svg/predict.svg'
import enter from '../../images/svg/enter.svg'
import win from '../../images/svg/win.svg'
import { Path } from '../../paths'
import { CallToAction } from '../../components/buttons/buttons'
import { useMatch } from 'react-router-dom'
import { Games } from '../../features/home/game'
import { TourConduction } from '../../features/game/rules/tour'
import { Tours } from '../../features/game/rules/tour/tours'

export function Page({ children, showWhen = true }: { children: ReactNode, showWhen?: boolean }) {
    const isLoadingUser = useLoadUser()
    const isHome = useMatch("/")    

    return !isLoadingUser && showWhen
        ? <>
            <KnownErrorsHandler />
            <SigninToApi />
            <Header contents={isHome ? <HeaderContents /> : null}/>
            {children}
            <Footer />
        </>
        : <Loader />
}

function Highlight({children}: {children:string}) {
  return <span className={headerStyles.highlight}>{children}</span>
}

function HeaderContents() {
    return <div className={headerStyles.headerContents}>
      <h1 className={headerStyles.h1}>Football Prediction Games</h1>            

      <Games />

      <TourConduction tour={Tours['epl']} />

      {/* <h2 className={merge(headerStyles.h2, headerStyles.noBottomMargin)}>MORE PLAYERS</h2>
      <h2 className={merge(headerStyles.h2, headerStyles.noTopMargin)}><Highlight>BIGGER</Highlight> PRIZE POT<Highlight>!</Highlight></h2>  */}
      <h2 className={headerStyles.h2alt}>uPredict tests your <Highlight>skills</Highlight> against our <Highlight>community</Highlight> and your friends.  <Highlight>Respond</Highlight> to results and make <Highlight>in game</Highlight> changes, all while having fun and feeling like a <Highlight>superhero</Highlight>!</h2>
      <h2 className={headerStyles.h2}>10s of Thousands Paid Out in <Highlight>Prizes</Highlight></h2>  
      
      <ul className={headerStyles.baubles}>
        <li><a aria-label='Predict' href={`/#${Path.dashboard}`}><img src={predict} alt='Predict' /></a></li>
        <li><a aria-label='Enter' href={`/#${Path.dashboard}`}><img src={enter} alt='Enter' /></a></li>
        <li><a aria-label='Win' href={`/#${Path.dashboard}`}><img src={win} alt='Win' /></a></li>
      </ul>
  
      <div className={headerStyles.playNow}>                
        <CallToAction aria-label='Play Now' to={Path.signup}>SIGN UP</CallToAction>
      </div>
    </div>
  }

function Loader() { return <div className={styles.loader}></div> }
