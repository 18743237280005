import { usePreload } from '../../../../hooks/use-preload'
import { useTour } from './use-tour'
import styles from '../rules.module.css'
import { Tour, Step } from '.'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackwardFast, faPlay, faRepeat } from '@fortawesome/free-solid-svg-icons'
import { AnimatePresence, motion } from 'framer-motion'
import { useIsLargeScreen } from '../../../../hooks/use-media-query'
import cx from 'classnames'


export function TourConduction({ tour }: { tour: Tour }) {
  usePreload(tour.reels.flatMap(reel => reel).filter(isImageStep).map(x => x.image))
  const tourRunner = useTour(tour)
  return <GameAspect tour={tourRunner} />
}

function GameAspect({ tour }: { tour: ReturnType<typeof useTour> }) {
  const advanceText = tour.reelIndex === 0 ? 'Start' : 'Continue'
  const animationLimit = useAnimationLimit()
  const showControls = tour.canRespondTo('restart-tour') || tour.canRespondTo('replay-reel') || tour.canRespondTo('start')

  return <>
    <div className={styles.aspect}>
      <div className={styles.caption}>
        {showControls ?
          <div className={styles.controls}>
            {tour.canRespondTo('restart-tour') ? <FontAwesomeIcon icon={faBackwardFast} title='Restart' onClick={tour.restartTour} className={styles.control} /> : null}
            {tour.canRespondTo('replay-reel') && tour.reelIndex !== 0 ? <FontAwesomeIcon icon={faRepeat} title='Repeat Section' onClick={tour.replayReel} className={styles.control} /> : null}
            {tour.canRespondTo('start') ? <FontAwesomeIcon icon={faPlay} title={advanceText} onClick={tour.start} className={styles.control} /> : null}
          </div> : null}

        <AnimatePresence exitBeforeEnter={true}>
          <motion.div key={tour.step.caption} className={cx(styles.captionText, { [styles.captionFaded]: showControls })} initial={{ x: animationLimit, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: -animationLimit, opacity: 0 }}>
            {tour.step.caption}
          </motion.div>
        </AnimatePresence>
      </div>
      {isImageStep(tour.step) ?
        <div className={styles.image} style={{ backgroundImage: `url(${tour.step.image})`, backgroundPositionY: `${tour.step.top}%` }} /> :
        <div className={styles.jsx}>{tour.step.render()}</div>}
    </div>
  </>
}

function useAnimationLimit() {
  const isLargeScreen = useIsLargeScreen()
  return isLargeScreen ? 600 : 200
}

function isImageStep(step: Step): step is Extract<Step, { type: 'image' }> {
  return step.type === 'image'
}
