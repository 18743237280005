import { useCallback } from 'react'
import { ProblemHandler } from '../utilities/http/jsonApi'
import { usePost } from '../utilities/http/use-authenticated-api'
import routes from './routes'

export function useSigninRequest() {
  const post = usePost()
  return useCallback(async (problemHandler?: ProblemHandler): Promise<void> => {
    await post(routes.signin, {}, problemHandler)
  }, [post])
}